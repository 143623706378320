@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.home{
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('./assets/images/about.jpg');
  background-size: cover;
}
.btn {
  background-image: linear-gradient(45deg, black 50%, rgb(202 138 4) 50%);
  background-size: 250% 100%;
  background-position: right bottom;
  transition: background-position .5s ease;
}

.btn:hover {
  background-position: left top;
}
.btn2 {
  background-image: linear-gradient(45deg, rgb(202 138 4) 50%, black 50%);
  background-size: 250% 100%;
  background-position: right bottom;
  transition: background-position .5s ease;
  opacity: 0.8;
}

.btn2:hover {
  background-position: left top;
}
.btn3 {
  background-image: linear-gradient(45deg, rgb(202 138 4) 50%, black 50%);
  background-size: 250% 100%;
  background-position: right bottom;
  transition: background-position .5s ease;
}

.btn3:hover {
  background-position: left top;
}

.btn4 {
  background-image: linear-gradient(45deg, rgb(202 138 4) 50%, black 50%);
  background-size: 250% 40%;
  background-position: right bottom;
  transition: background-position .5s ease;
}

.btn4:hover {
  background-position: left top;
}
.wrapper {
  width: 100%;
}
.wrapper::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-image:linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))
}
.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
.about{
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('./assets/images/about.png');;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
}
.contact2 {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('./assets/images/court.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.contact {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('./assets/images/contact.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.patner {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url('./assets/images/court.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
}
.jo {
  background: url('./assets/images/jo.jpeg');;
  background-position: 50% 30%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 95vh;
}
.ray {
  background: url('./assets/images/ray.jpg');;
  background-position: 50% 25%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 95vh;
}
